import styled from "styled-components";
import { mediaQuery } from "../../lib/service/service";

const ContainerSC = styled("div")`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 40px;
  ${mediaQuery(601)} {
    padding: 0px 10px;
  }
`;

export const ContainerStyle = () => ({
  ContainerSC,
});
