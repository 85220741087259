import { HThreeTitle } from "../../../ui/Titles/style";
import { ContactBlockStyle } from "./style";

const ContactBlock = () => {
  return (
    <ContactWrapperSC>
      <PartWraperSC>
        <HThreeTitle isUppercase={true}>КОНТАКТЫ</HThreeTitle>
        <ContactItem href="tel:+7(999)585-96-05">+7(999)585-96-05</ContactItem>
        <ContactItem href="mailto:averyanovanton@stit.online">
          averyanovanton@stit.online
        </ContactItem>
      </PartWraperSC>
      <PartWraperSC>
        <HThreeTitle isUppercase={true}>Соцсети</HThreeTitle>
        <ContactItem href="https://vk.com/ant.aversg">VK</ContactItem>
        <ContactItem href="https://t.me/tyfin">Telegram</ContactItem>
      </PartWraperSC>
    </ContactWrapperSC>
  );
};

const { ContactItem, PartWraperSC, ContactWrapperSC } =
  ContactBlockStyle();

export default ContactBlock;
