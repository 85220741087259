import Swiper from "./Swiper/Swiper";
import { titleStyle } from "./style";
import oneImg from "./assets/oneImg.svg";
import twoImg from "./assets/twoImg.svg";
import threeImg from "./assets/threeImg.svg";
import fourImg from "./assets/fourImg.svg";
import chevron from "./assets/chevrons-down.svg";
import { useResize } from "../../lib/hooks/useResize";
import { HOneTitle } from "../../ui/Titles/style";
// project
const TitleBlock = ({projectRef}) => {
  const { isScreenSm, isScreenTable } = useResize();


  const scrollToProject = () => {
    if(projectRef.current) {
      projectRef.current.scrollIntoView();
    }
  }

  const Icons = (
    <ProjectImgWrapperSC>
      <LinkWrapperIconSC onClick={scrollToProject} href="#Machine_learning">
        <ImgSC src={fourImg} />
      </LinkWrapperIconSC>
      <LinkWrapperIconSC onClick={scrollToProject} href="#Health">
        <ImgSC src={threeImg} />
      </LinkWrapperIconSC>
      <LinkWrapperIconSC onClick={scrollToProject} href="#Blockchain">
        <ImgSC src={twoImg} />
      </LinkWrapperIconSC>
      <LinkWrapperIconSC onClick={scrollToProject} href="#Security">
        <ImgSC src={oneImg} />
      </LinkWrapperIconSC>
    </ProjectImgWrapperSC>
  );

  return (
    <BackgroundBlockSC>
      <CustomContainerSC>
        <BlockWrapperSC>
          <Swiper />
          <RightBlockSC>
            <HOneTitle>Аверьянов Антон</HOneTitle>
            <SubTitleSC>Product manager, CEO “ST IT”</SubTitleSC>
            <DecriptionSC>
              Создаю инновационные решения, которые помогут Вашему бизнесу
            </DecriptionSC>
            {!isScreenSm && Icons}
            <a href="#form">
              <ContactButton>СВЯЗАТЬСЯ</ContactButton>
            </a>
            {isScreenSm && Icons}
          </RightBlockSC>
        </BlockWrapperSC>
        {isScreenSm && isScreenTable && <ChevronsDown src={chevron} />}
        {/* {!isScreenTable  && <Swiper/>} */}
      </CustomContainerSC>
    </BackgroundBlockSC>
  );
};

const {
  ImgSC,
  SubTitleSC,
  ChevronsDown,
  RightBlockSC,
  DecriptionSC,
  ContactButton,
  BlockWrapperSC,
  CustomContainerSC,
  BackgroundBlockSC,
  LinkWrapperIconSC,
  ProjectImgWrapperSC,
} = titleStyle();

export default TitleBlock;
