import styled from "styled-components";
import { mediaQuery } from "../../../lib/service/service";

const ContactWrapperSC = styled("section")`
  display: flex;
  justify-content: space-between;
  max-width: 595px;
  width: 100%;
  margin-bottom: 68px;
  padding: 0px;
  ${mediaQuery(601)} {
    display: block;
    padding-top: 30px;
    margin-bottom: 25px;
  }
`;
const PartWraperSC = styled("div")`
  ${mediaQuery(601)} {
    margin-bottom: 40px;
  }
`;

// const TitleSC = styled("h3")`
//   margin-bottom: 40px;
//   font-weight: 700;
//   font-size: 22px;
//   line-height: 27px;
//   letter-spacing: 0.15em;
//   text-transform: uppercase;
//   color: #ffffff;
//   ${mediaQuery(760)} {
//     font-size: 16px;
//   }
//   ${mediaQuery(601)} {
//     font-size: 14px;
//     line-height: 17px;
//     letter-spacing: 0.15em;
//     margin-bottom: 20px;
//   }
// `;

const ContactItem = styled("a")`
  display: block;
  font-weight: 400;
  font-size: 18px;
line-height: 22px;
  color: #656565;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 12px;
  transition: all 0.3s;
  
  &:hover {
    color: #e63434;
  }

  ${mediaQuery(760)} {
    font-size: 16px;
  }
  ${mediaQuery(601)} {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const ContactBlockStyle = () => ({
  ContactItem,
  PartWraperSC,
  ContactWrapperSC,
});
