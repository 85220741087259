import styled,{keyframes} from "styled-components";
import { mediaQuery } from "../../lib/service/service";
import { SCREEN_SM, SCREEN_TABLE, } from "../../lib/const/breakpoint";



const chevronAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  to {
    transform: translateY(0px);
  }
`;

const BackgroundBlockSC = styled("div")`
  background: url(/titleDesktopBG.png) 0% 80% / 109% no-repeat;
  ${mediaQuery(SCREEN_TABLE)} {
    background: none;
  }
  ${mediaQuery(SCREEN_SM)} {
    background: none;
  }
`;

const BlockWrapperSC = styled("section")`
  width: 100%;
  padding-top: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background-position: 10% 10%;
  margin-bottom: 28px;
  ${mediaQuery(SCREEN_TABLE)} {
    display: block;
  }
  ${mediaQuery(SCREEN_SM)} {
    height: 95vh;
display: flex;
flex-direction: column;
justify-content: space-between;
    padding-top: 0;
    width: auto;
    padding-bottom: 21px;
    margin-bottom: 0px;
  }
`;

const RightBlockSC = styled("div")`
  max-width: 661px;
  width: 49%;
  ${mediaQuery(SCREEN_TABLE)} {
    width: 100%;
  }
  ${mediaQuery(SCREEN_SM)} {
    flex:0 0 238px;
    padding: 0 10px;
  }
`;

const LinkWrapperIconSC = styled("a")`
  cursor: pointer;
`;

// const TitleSC = styled("h1")`
//   font-style: normal;
//   color: #ffffff;
//   font-weight: 700;
//   font-size: 50px;
// line-height: 61px;
//   margin-bottom: 6px;
//   ${mediaQuery(1280)} {
//     font-size: 54px;
//     line-height: 57px;
//   }
//   ${mediaQuery(SCREEN_XL)} {
//     font-size: 48px;
//     line-height: 51px;
//   }
//   ${mediaQuery(SCREEN_SM)} {
//     font-weight: 700;
//     font-size: 35px;
//     line-height: 42px;
//   }
// `;

const SubTitleSC = styled("h2")`
  color: #e63434;
  font-weight: 700;
  font-size: 20px;
line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 36px;
  ${mediaQuery(SCREEN_SM)} {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
  }
`;

const DecriptionSC = styled("p")`
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
line-height: 24px;
  letter-spacing: 0.02em;
  margin-bottom: 36px;
  max-width: 490px;
  ${mediaQuery(SCREEN_SM)} {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
    max-width: 336px;
  }
`;

const ProjectImgWrapperSC = styled("div")`
  max-width: 261px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 84px;
  ${mediaQuery(SCREEN_SM)} {
    height: 34px;
    width: 166px;
    margin-bottom: 20px;
  }
`;

const ImgSC = styled("img")`
  height: 42px;
  ${mediaQuery(SCREEN_SM)} {
    height: 34px;
  }
`;

const ContactButton = styled("button")`
transition: all 0.5s;
  padding: 0px 30px;
  width: 171px;
  height: 46px;
  background: #1c1c1c;
  border: 1px solid #e63434;
  border-radius: 4px;
  text-transform: uppercase;
  color: #e63434;
  cursor: pointer;
  font-weight: 700;
  font-size: 15px;
line-height: 18px;
  margin-bottom: 36px;
  letter-spacing: 0.15em;

  &:hover {
    ${({isDisabled}) => {
      if(!isDisabled) {
        return `
          color: #1C1C1C;
          background-color:#E63434;
        `
      }
    }}
  }

  ${mediaQuery(SCREEN_SM)} {
    margin-bottom: 0px;
    width: 100%;
    height: 45px;
    padding: 15px 0px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.15em;
  }
`;

// const BottomWrapperSC = styled('div')`
//     padding-right:
// `;

const ChevronsDown = styled("img")`
  display: block;
  width: 23px;
  height: 21px;
  margin: 0 auto;
  margin-bottom: 50px;
  animation: ${chevronAnimation} 2.5s ease-in-out infinite;
`;

const CustomContainerSC = styled('div')`
   max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 40px;
  ${mediaQuery(601)} {
    padding: 0px;
  }
`;

export const titleStyle = () => ({
  ImgSC,
  SubTitleSC,
  ChevronsDown,
  RightBlockSC,
  DecriptionSC,
  ContactButton,
  BlockWrapperSC,
  CustomContainerSC,
  BackgroundBlockSC,
  LinkWrapperIconSC,
  ProjectImgWrapperSC,
});
