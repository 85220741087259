import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { mediaQuery } from "../../lib/service/service";
import { HTwoTitle } from "../../ui/Titles/style";

const SectionSC = styled("section")``;

const CustomContainerSC = styled('div')`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 40px;
  ${mediaQuery(601)} {
    padding: 0px ;
  }
`;

const CustomHTwoTitleSC = styled(HTwoTitle)`
  margin-left: 10px;
`

// const TitleSC = styled("h2")`
//   font-weight: 700;
//   font-size: 22px;
//   line-height: 27px;
//   letter-spacing: 0.15em;
//   color: #ffffff;
//   margin-bottom: 80px;
//   ${mediaQuery(601)} {
//     font-size: 14px;
//     line-height: 17px;
//     margin-bottom: 20px;
//   }
// `;

export const ProjectBlockStyle = () => ({
  SectionSC,
  CustomContainerSC,
  CustomHTwoTitleSC
});
