import { createGlobalStyle } from "styled-components";
import { mediaQuery } from "../lib/service/service";

const GlobalStyle = createGlobalStyle`
  body {

    margin: 0;
    background: #1C1C1C;
  line-height: 1; 
  text-rendering: optimizeLegibility;
  text-decoration-skip: objects;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  ${mediaQuery(601)} {
    padding: 10px 0px;
  }
  }
  * {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family:Inter;
  }
  p {
    margin: 0;
  }
  h1,h2,h3,h4,h5,h6 {
    margin:0;
  }
  a {
    text-decoration:none;
  }
  button,
input,
optgroup,
select,
textarea {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  font: inherit; /* По дефолту, шрифтовые свойства, для этих элементов не наследуются */
  color: inherit;
  letter-spacing: inherit;
}
`;

export default GlobalStyle;
