import styled from "styled-components";
import { mediaQuery } from "../../../lib/service/service";

const FormSectionSC = styled("section")`
position:relative;
  ${mediaQuery(601)} {
    margin-bottom: 70px;
  }
`;

// const FormTitleSC = styled("h3")`
//   font-weight: 700;
//   font-size: 22px;
//   line-height: 27px;
//   letter-spacing: 0.15em;
//   text-transform: uppercase;
//   color: #ffffff;
//   margin-bottom: 40px;
//   ${mediaQuery(680)} {
//     font-size: 18px;
//     line-height: 22px;
//   }
//   ${mediaQuery(601)} {
//     font-size: 14px;
//     line-height: 17px;
//     margin-bottom: 20px;
//   }
// `;

const FormSC = styled("form")``;

const InputSC = styled("input")`
transition: all 0.5s;
  padding: 20px;
  gap: 20px;
  width: 100%;
  height: 50px;
  border: 1px solid ${({ isEmpty }) => (isEmpty ? "#656565" : "#FFFFFF")};
  margin-bottom: 18px;
  color: #ffffff;
  font-size: 14px;
line-height: 17px;
  &:hover {
    border: 1px solid #E63434;
  }
  &:focus-visible {
    border: 1px solid ${({ isEmpty }) => (isEmpty ? "#656565" : "#E63434")};
    outline: none;
  }

  &:focus {
    outline: none;
  }
  ${mediaQuery(601)} {
    padding: 15px 20px;
    gap: 20px;
    width: 100%;
    height: 47px;
    background: #1c1c1c;
    margin-bottom: ${({ isError }) => (isError ? "5px" : "15px")};
  }
`;

const InputErrorSC = styled("p")`
  ${mediaQuery(601)} {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ff0000;
    margin-bottom: 10px;
    padding-left: 20px;
  }
`;


const ButtonSubmitSC = styled("button")`
transition: all 0.5s;
  padding: 16px 30px;
  width: 100%;
  height: 50px;
  background:  "#1c1c1c";
  border: 1px solid ${({ isDisabled }) => (isDisabled ? "#6D2121" : "#e63434")};
  font-weight: 700;
  font-size: 15px;
line-height: 18px;
  letter-spacing: 0.15em;
  color: ${({ isDisabled }) => (isDisabled ? "#6D2121" : "#e63434")};
  cursor: pointer;
  text-transform: uppercase;
  align-items: center;

  &:hover {
    ${({isDisabled}) => {
      if(!isDisabled) {
        return `
          color: #1C1C1C;
          background-color:#E63434;
        `
      }
    }}
  }

  ${mediaQuery(601)} {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    padding: 15px 30px;
    height: 45px;
  }
`;

const SuccessWrapperSC = styled('div')`
 transition: all 0.2s;
position: ${({isSuccess}) => isSuccess?'static':'absolute'};
  opacity: ${({isSuccess}) => isSuccess?1:0};
  overflow: ${({isSuccess}) => isSuccess?'visible':'hidden'};
  visibility: ${({isSuccess}) => isSuccess?'visible':'hidden'};
  width: ${({isSuccess}) => isSuccess?'auto':'0'};
  height: 186px; 
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessTitleSC = styled('h3')`
font-weight: 700;
font-size: 12px;
line-height: 15px;
letter-spacing: 0.15em;
text-transform: uppercase;
color: #FFFFFF;
`;

export const FormFeedbackStyle = () => ({
  FormSC,
  InputSC,
  InputErrorSC,
  FormSectionSC,
  ButtonSubmitSC,
  SuccessTitleSC,
  SuccessWrapperSC,
});
