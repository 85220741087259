import { swiperStyle } from "./style";
import { Pagination,Navigation } from "swiper";
import "swiper/css/navigation";
const SwiperComponent = () => {
  return (
    <SwiperSC
      modules={[Pagination,Navigation]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      loop={true}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlideSC  >
        <ImgSC verticalPosition={0} src="SlideOne.webp" />
      </SwiperSlideSC>
      <SwiperSlideSC  >
        <ImgSC verticalPosition={0} src="SlideTwo.webp" />
      </SwiperSlideSC>
      <SwiperSlideSC  >
        <ImgSC verticalPosition={0} src="SlideThree.webp" />
      </SwiperSlideSC>
      {/* <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div> */}
    </SwiperSC>
  );
};

const { ImgSC, SwiperSC, SwiperSlideSC } = swiperStyle();

export default SwiperComponent;
