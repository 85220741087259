import Logo from "./assets/Logo.svg";
import { SlideThreeStyle } from "./style";

const SlideThree = () => {
  return (
    <SlideWrapperSC >
      <TopWrapperSC>
        <TagsWrapperSC>
          <TagsSC c>{"Здоровье и фитнес"}</TagsSC>
          <TagsSC>{"Метрики"}</TagsSC>
        </TagsWrapperSC>
        <SlideTitleWrapperSC>
          <LogoSliderSC src={Logo} />
          <SlideTitleSC>{"Gym App"}</SlideTitleSC>
        </SlideTitleWrapperSC>
        <TextWrapperSC>
          <SlideTextSC>
            {"Мобильное приложения для спортзалов. Включает в себя"}{" "}
          </SlideTextSC>
          <SpanTextSC>
            {
              "личного интеллектуального помощника и ведет учет всех метрик клуба"
            }
          </SpanTextSC>
          <SlideTextSC>
            {", что позволяет улучшать лояльность пользователей."}
          </SlideTextSC>
        </TextWrapperSC>
      </TopWrapperSC>
      <ImageSC src={"/ProjectSlideThree.webp"} />
    </SlideWrapperSC>
  );
};

const {
  TagsSC,
  ImageSC,
  SpanTextSC,
  SlideTextSC,
  TopWrapperSC,
  SlideTitleSC,
  LogoSliderSC,
  TextWrapperSC,
  TagsWrapperSC,
  SlideWrapperSC,
  SlideTitleWrapperSC,
} = SlideThreeStyle();

export default SlideThree;
