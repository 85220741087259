import styled, { css } from "styled-components";
import { Swiper, SwiperSlide, } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { mediaQuery } from "../../../lib/service/service";
import { SCREEN_TABLE,  } from "../../../lib/const/breakpoint";


const swiperNavigationCSS = css`
mask-image: url('/arrow.svg');
    mask-repeat: no-repeat;
    background-color:#1C1C1C;
    width:30%;
    height: 100%;
    top: 0;
    
`;

const SwiperSC = styled(Swiper)`
transition: all 0.5s;
  max-width: 486px;
  width: 49%;
  height: 588px;
  margin: 0;
  
  & > .swiper-button-prev {
    ${swiperNavigationCSS}
    mask-position:center right;
    left: 18px;
    transform: rotateY(180deg);
    &:hover {
      background-color: #E53434;
    }
    &::after {
      content:''
    }
  }
  & > .swiper-button-next{
    mask-position:center right;
    ${swiperNavigationCSS}
    right: 18px;
    &:hover {
      background-color: #E53434;
    }
    &::after {
      content:''
    }
  }

  & > .swiper-pagination {
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%);
    height: 30px;
    width: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > .swiper-pagination-bullet {
      opacity: 1;
      height: 7px;
      width: 7px;
      background: #d9d9d9;
    }

    & > .swiper-pagination-bullet-active {
      background: #e63434;
    }
  }




  ${mediaQuery(SCREEN_TABLE)} {
    max-width: 1024px;
    width: 100%;
    height: 675px;
    margin-bottom: 25px;
    & > .swiper-button-prev {
      display: none;
    }
    & > .swiper-button-next {
      display: none;
    }
    & > .swiper-pagination {
    }
  }

  ${mediaQuery(601)} {
    width: 100%;
    height: 580px;
    margin-bottom:0;
    
    & > .swiper-pagination {
      position:absolute;
      bottom: 0;
      width: 60px;
      height: 20px;

      & > .swiper-pagination-bullet {
        height: 5px;
        width: 5px;
      }

      & > .swiper-pagination-bullet-active {
        height: 5px;
        width: 5px;
      }
    }
  }
`;

const SwiperSlideSC = styled(SwiperSlide)`
  width: 100%;
  height: 545px;
  border-radius: 30px;
  ${mediaQuery(SCREEN_TABLE)} {
    overflow: hidden;
    height: 630px;
  }

  ${mediaQuery(601)} {
    width: 100%;
    height: 94%;
  }
`;

const ImgSC = styled("img")`
  width: 100%;
  height: 545px;
  border-radius: 30px;
  object-fit: cover;
  object-position: ${({verticalPosition}) => `100% ${verticalPosition}%`};
  ${mediaQuery(SCREEN_TABLE)} {
    height: 830px;
    /* object-position: 100% 22%; */
  }
  ${mediaQuery(701)} {
    /* object-position: 100% 70%; */
  }
  ${mediaQuery(650)} {
    /* object-position: 100% 100%; */
  }
  ${mediaQuery(601)} {
    max-width: 100%;
    width: 95%;
    margin: 0 auto;
    height: 100%;
    display: block;
    /* object-position: 100% 20%; */
  }
  ${mediaQuery(501)} {
    /* object-position: 100% 25%; */
  }
  ${mediaQuery(401)} {
    /* object-position: 100% 50%; */
  }
  ${mediaQuery(350)} {
    /* object-position: 100% 100%; */
  }
`;

export const swiperStyle = () => ({
  ImgSC,
  SwiperSC,
  SwiperSlideSC,
});
