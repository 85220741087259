import styled from "styled-components";
import { mediaQuery } from "../../lib/service/service";
import { SCREEN_TABLE } from "../../lib/const/breakpoint";

const FooterWrapperSC = styled("footer")`
  background: url("/Map-BG.webp") no-repeat;
  background-size: cover;
  /* background-size: 100% 100%; */
  padding: 93px 20px;
  ${mediaQuery(SCREEN_TABLE)} {
    padding: 40px 20px;
    padding-bottom: 40px;
  }
  ${mediaQuery(601)} {
    background: none;
    padding: 0px;
  }
`;

const WindowsWrapperSC = styled("div")`
  padding: 50px;
  max-width: 570px;
  width: 100%;
  background: #1c1c1c;
  margin: 0 auto;
  ${mediaQuery(601)} {
    padding: 0px 10px;
  }
`;

export const FooterStyle = () => ({
  FooterWrapperSC,
  WindowsWrapperSC,
});
