import styled from "styled-components";
import { mediaQuery } from "../../../../lib/service/service";
import { SCREEN_TABLE } from "../../../../lib/const/breakpoint";

const BlockWrapperSC = styled("div")`
  padding: 24px;
  /* padding-bottom: 244px; */
  max-width: 480px;
  height: 488px;
  width: 49%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 30px;
  border: 2px solid ${({ borderWrapperColor }) => borderWrapperColor};
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;

  ${mediaQuery(SCREEN_TABLE)} {
    padding: 10px;
  }
  ${mediaQuery(950)} {
    padding-bottom: 100px;
    min-height: 500px;
  }
  ${mediaQuery(780)} {
    min-height: 440px;
    
  }
  ${mediaQuery(630)} {
    min-height: 380px;
    height: 430px;
  }
`;

const TagsWrapperSC = styled("div")`
  display: flex;
  margin-bottom: 24px;
  ${mediaQuery(950)} {
    margin-bottom: 15px;
  }
`;

const TagSC = styled("div")`
  padding: 7px 12px;
  height: 30px;
  color: ${({ tagsColorText }) => tagsColorText};
  border: 2px solid ${({ tagsColor }) => tagsColor};
  border-radius: 39px;
  margin-right: 10px;
  font-size: 12px;
line-height: 12px;
font-weight: 700;
  ${mediaQuery(SCREEN_TABLE)} {
    padding: 8px 12px;
    font-size: 12px;
  }
  ${mediaQuery(850)} {
    height: 25px;
    padding: 5px 10px;
  }
  ${mediaQuery(820)} {
    padding: 5px 7px;
  }
  ${mediaQuery(800)} {
    font-size: 11px;
    margin-right: 5px;
  }
  ${mediaQuery(730)} {
    font-size: 9px;
  }
  ${mediaQuery(660)} {
    font-size: 8px;
  }
`;

const TitleWrapperSC = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  ${mediaQuery(950)} {
    margin-bottom: 15px;
  }
`;

const LogoSC = styled("img")`
  width: 30px;
  height: 30px;
  ${mediaQuery(950)} {
    width: 40px;
    height: 40px;
  }
  ${mediaQuery(800)} {
    width: 30px;
    height: 30px;
  }
  ${mediaQuery(630)} {
    width: 25px;
    height: 25px;
  }
`;

const TitleSC = styled("h3")`
  font-weight: 700;
  font-size: 20px;
line-height: 24px;
  margin-left: 16px;
  color: ${({ titleColor }) => titleColor};
  ${mediaQuery(950)} {
    line-height: 33px;
    font-size: 23px;
  }
  ${mediaQuery(800)} {
    line-height: 28px;
    font-size: 18px;
  }
  ${mediaQuery(630)} {
    font-size: 14px;
    line-height: 24px;
  }
`;

const DescrSC = styled("p")`
  color: ${({ textColor }) => textColor};
  font-weight: 600;
  font-size: 16px;
line-height: 140%;
  margin-bottom: ${({ textMarginBottom }) => `${textMarginBottom}px`};
  & > span {
    color: ${({ spanColor }) => spanColor};
  }
  ${mediaQuery(950)} {
    font-size: 17px;
  }
  ${mediaQuery(800)} {
    font-size: 16px;
    margin-bottom: ${({ textMarginBottom }) => `${textMarginBottom - 10}px`};
  }
  ${mediaQuery(630)} {
    font-size: 12px;
  }
`;

const LinkWrapperSC = styled("a")`
  display: flex;
  align-items: center;
  margin-bottom: 27px;
`;

const TextLinkSC = styled("p")`
  font-weight: 700;
  font-size: 16px;
line-height: 140%;
  color: #f6f6f6;
  margin-right: 6px;
  cursor: pointer;
  ${mediaQuery(850)} {
    font-size: 14px;
  }
  ${mediaQuery(650)} {
    font-size: 12px;
  }
`;

const LinkIconSC = styled("img")`
  width: 11px;
  height: 11px;
  ${mediaQuery(850)} {
    width: 13px;
    height: 13px;
  }
`;

const ImgBlockSC = styled("img")`
/* border-radius: 18px; */
/* object-fit:contain; */
border-radius: ${({borderRadius}) => `${borderRadius}px`};
height: ${({imgHeight}) => `${imgHeight}px`};
  min-width: ${({ imgWidth }) => `${imgWidth}px`};
  width: ${({imgSize}) => `${imgSize}${imgSize ===100 ?'%':'px'}`};
  position: absolute;
  bottom: ${({ bottomAbsolute }) => `${bottomAbsolute}px`};
  left: 24px;
  ${mediaQuery(SCREEN_TABLE)} {
    left: 10px;
    max-width: ${({imgSize}) => `${imgSize}%`};
    height: ${({imgHeight}) => `${imgHeight}px`};
  width: ${({ imgWidth }) => `${imgWidth}px`};
    
  }
`;

export const ProjectBlockStyle = () => ({
  TagSC,
  LogoSC,
  TitleSC,
  DescrSC,
  TextLinkSC,
  LinkIconSC,
  ImgBlockSC,
  LinkWrapperSC,
  TagsWrapperSC,
  TitleWrapperSC,
  BlockWrapperSC,
});
