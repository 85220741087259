import ContactBlock from "./ContactBlock/ContactBlock";
import FormFeedback from "./FormFeedback/FormFeedback";
import { FooterStyle } from "./style";

const Footer = () => {
  return (
    <FooterWrapperSC>
      <WindowsWrapperSC>
        <ContactBlock />
        <FormFeedback />
      </WindowsWrapperSC>
    </FooterWrapperSC>
  );
};

const { FooterWrapperSC, WindowsWrapperSC } = FooterStyle();

export default Footer;
