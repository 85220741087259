import ProjectBlock from "./ProjectBlock/ProjectBlock";
import { DesktopProjectStyle } from "./style";

import Logo from "./assets/Logo.svg";
import LogoTwo from "./assets/LogoTwo.svg";
import LogoThree from "./assets/LogoThree.svg";
import LogoFour from "./assets/LogoFour.svg";
import { useResize } from "../../../lib/hooks/useResize";
const DesktopProject = () => {
  const { isScreenTable } = useResize();

  return (
    <ProjectWrapperSC>
      <ProjectBlock
        textColor={"#FFFFFF"}
        tagsColorText={"#FFFFFF"}
        borderWrapperColor={"#1C1C1C"}
        backgroundColor={"#2A2735"}
        tagOne={"Машинное обучение"}
        tagTwo={"Базы данных"}
        tagsColor={"#56A2FD"}
        logo={Logo}
        title={"SheetsGPT"}
        text={
          "Нейросеть для анализа баз данных и таблиц. Позволяет делать отчеты, строить графики, анализировать данные<span> с помощью текстовых запросов.</span>"
        }
        imgSrc={"/ProjectDesktopTwo.webp"}
        linkHref={"https://sheetsgpt.ru/"}
        isLink={true}
        spanColor={"#56A2FD"}
        titleColor={"#F6F6F6"}
        textLink={"Ознакомиться подробнее"}
        bottomAbsolute={-50}
        textMarginBottom={24}
        id="Machine_learning"
        borderRadius={9}
        imgSize={100}
        imgHeight={250}
        // imgWidth={620}
      />

      <ProjectBlock
        textColor={"#1C1C1C"}
        tagsColorText={"#1C1C1C"}
        borderWrapperColor={"#1C1C1C"}
        backgroundColor={"#FFE9E3"}
        tagOne={"Блокчейн"}
        tagTwo={"Электронный документооборот"}
        tagsColor={"#DE491B"}
        logo={LogoTwo}
        title={"Blockchain archive"}
        text={
          "Blockchain Archive - надежная распределенная сеть для электронного документооборота предприятия, <span>исключающая возможность подделки и упрощающая процесс подписи документов.</span>"
        }
        imgSrc={"/ProjectDesktopOne.webp"}
        isLink={false}
        spanColor={"#DE491B"}
        titleColor={"#1C1C1C"}
        bottomAbsolute={-70}
        textMarginBottom={24}
        id="Blockchain"
        imgHeight={272}
        imgWidth={508}
      />

      <ProjectBlock
        textColor={"#F6F6F6"}
        tagsColorText={"#FFFFFF"}
        borderWrapperColor={"#1C1C1C"}
        backgroundColor={"#30024F"}
        tagOne={"Здоровье и фитнес"}
        tagTwo={"Метрики"}
        tagsColor={"#FFFFFF"}
        logo={LogoThree}
        title={"Gym App"}
        text={
          "Мобильное приложения для спортзалов. Включает в себя <span>личного интеллектуального помощника и ведет учет всех метрик клуба,</span> что позволяет улучшать лояльность пользователей."
        }
        bottomAbsolute={-13}
        imgSrc={"/ProjectDesktopThree.webp"}
        isLink={false}
        spanColor={"#FE8C45"}
        titleColor={"#F6F6F6"}
        id="Health"
        textMarginBottom={24}
        imgWidth={420}
        imgSize={100}
      />
      <ProjectBlock
        textColor={"#F6F6F6"}
        tagsColorText={"#FFFFFF"}
        borderWrapperColor={"#1C1C1C"}
        backgroundColor={"#1C399E"}
        tagOne={"Системы безопасности"}
        tagTwo={"Защита информации"}
        tagsColor={"#5C7DF0"}
        logo={LogoFour}
        title={"Защита помещений от проникновения"}
        text={
          "Система распределения средств физической защиты на основе плана здания, построенная на <span>собственной математической модели.</span>"
        }
        imgSrc={"/ProjectSlideFour.webp"}
        linkHref={
          "https://www.researchgate.net/publication/367043115_MATHEMATICAL_MODEL_FOR_ASSESSING_THREATS_OF_PHYSICAL_INTRUSION_OF_AN_INTRUDER_INTO_A_PROTECTED_OBJECT"
        }
        isLink={true}
        spanColor={"#FFC700"}
        titleColor={"#F6F6F6"}
        textLink={"Ссылка на статью Research Gate"}
        imgWidth={isScreenTable ? 420 : 330}
        textMarginBottom={15}
        id="Security"
        imgSize={427}
      />
    </ProjectWrapperSC>
  );
};
const { ProjectWrapperSC } = DesktopProjectStyle();

export default DesktopProject;
