import styled from "styled-components";
import { mediaQuery } from "../../lib/service/service";

export const HTwoTitle = styled("h2")`
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #ffffff;
  ${mediaQuery(600)} {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
  }
`;


export const HOneTitle = styled('h1')`
  font-weight: 700;
font-size: 50px;
line-height: 61px;
color: #FFFFFF;
margin-bottom: 6px;
${mediaQuery(601)} {
  font-size: 35px;
line-height: 42px;
}
${mediaQuery(340)} {
  font-size: 32px;
  line-height: 39px;
}
`;


export const HThreeTitle = styled('h3')`
${({isUppercase}) => isUppercase?'text-transform: uppercase': ''};
max-width:1000px;
  font-weight: 700;
  font-size: 18px;
line-height: 24px;
  color: #ffffff;
  /* padding: 0 40px; */
  margin: 0 auto;
  letter-spacing: 0.15em;
  margin-bottom: 24px;
  ${mediaQuery(350)} {
    font-size: 18px;
    line-height: 22px;
  }
`;