import styled from "styled-components";

const ProjectWrapperSC = styled("div")`
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
`;

export const DesktopProjectStyle = () => ({
  ProjectWrapperSC,
});
