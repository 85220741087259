import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const SwiperSC = styled(Swiper)`
  width: 100%;
  height: 405px;
  & > .swiper-pagination {
    left: 50%;
    transform: translate(-50%);
    bottom: 0px;
    width: 80px;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > .swiper-pagination-bullet {
      opacity: 1;
      height: 5px;
      width: 5px;
      background: #d9d9d9;
    }
    & > .swiper-pagination-bullet-active {
      height: 5px;
      width: 5px;
      background: #e63434;
    }
  }
`;

const SwiperSlideSC = styled(SwiperSlide)``;

export const mobileProjectBlock = () => ({
  SwiperSC,
  SwiperSlideSC,
});
