import styled from "styled-components";

const SlideWrapperSC = styled("div")`
  background: ${({ colorBG }) => colorBG};
  border-radius: 25px;
  height: 380px;
  overflow: hidden;
  position: relative;
  width: 95%;
  margin: 0 auto;
`;

const TopWrapperSC = styled("div")`
  padding: 15px 15px 0px 15px;
`;

const TagsWrapperSC = styled("div")`
  display: flex;
  margin-bottom: 10px;
`;

const TagsSC = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: ${({ colorText }) => colorText};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 39px;
  height: 22px;
  padding: 5px 10px;
`;

const SlideTitleWrapperSC = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const LogoSliderSC = styled("img")`
  margin-right: 10px;
  width: 30px;
`;

const SlideTitleSC = styled("h3")`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${({ colorText }) => colorText};
`;

const SlideTextSC = styled("p")`
  max-width: ${({ widthText }) => (widthText ? `${widthText}px` : "100%")};
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: ${({ colorText }) => colorText};
`;

const SpanTextSC = styled("span")`
  color: ${({ spanColor }) => spanColor};
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
`;

const LinkWrapperSC = styled("a")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  height: 10px;
  cursor: pointer;
`;

const LinkIconSC = styled("img")``;

const LinkTextSC = styled("p")`
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #f6f6f6;
  margin-right: 10px;
`;

const BottomWrapperSC = styled("div")`
  padding-left: 15px;
`;

const ImageSC = styled("img")`
  height: 189px;
  position: absolute;
  bottom: ${({ bottom }) => `${bottom}px`};
  height: ${({imgHeight}) => `${imgHeight}px`};
  left: ${({ left }) => `${left}px`};
  min-width:  ${({imgWidth}) => `${imgWidth}px`};
  width: ${({widthSize}) => `${widthSize}%`};
  height: ${({ heightImg }) => `${heightImg}px`};
  border-radius: ${({borderRadius}) => `${borderRadius}px`}
  /* background: ${({ urlImage }) => `url(${urlImage}) no-repeat `}; */
  /* background-size:100% 100%; */
  /* border-radius: 14px 0px 25px 6px; */
`;

export const SlideOneStyle = () => ({
  TagsSC,
  ImageSC,
  LinkTextSC,
  LinkIconSC,
  SpanTextSC,
  SlideTextSC,
  TopWrapperSC,
  SlideTitleSC,
  LogoSliderSC,
  TagsWrapperSC,
  LinkWrapperSC,
  SlideWrapperSC,
  BottomWrapperSC,
  SlideTitleWrapperSC,
});
