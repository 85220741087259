import { SlideOneStyle } from "./style";

import Link from "./assets/Link.svg";
const Slide = ({
  bottom,
  left,
  linkHref,
  Title,
  TagOne,
  TagTwo,
  linkText,
  textColor,
  imgWidth,
  textMaxWidth,
  borderRadius = 0,
  backgroundColor,
  tagsBorderColor,
  widthSize = 100,
  logoSrc,
  spanColor,
  img,
  text,
  span,
  imgHeight,
  isLink,
  idSlide,
}) => {
  return (
    <SlideWrapperSC id={idSlide} colorBG={backgroundColor}>
      <TopWrapperSC>
        <TagsWrapperSC>
          <TagsSC colorText={textColor} borderColor={tagsBorderColor}>
            {TagOne}
          </TagsSC>
          <TagsSC colorText={textColor} borderColor={tagsBorderColor}>
            {TagTwo}
          </TagsSC>
        </TagsWrapperSC>
        <SlideTitleWrapperSC>
          <LogoSliderSC src={logoSrc} />
          <SlideTitleSC colorText={textColor}>{Title}</SlideTitleSC>
        </SlideTitleWrapperSC>
        <SlideTextSC widthText={textMaxWidth} colorText={textColor}>
          {text}
          <SpanTextSC spanColor={spanColor}>{span}</SpanTextSC>
        </SlideTextSC>
        {isLink && (
          <LinkWrapperSC target="_blank" href={linkHref}>
            <LinkTextSC>{linkText}</LinkTextSC>
            <LinkIconSC src={Link} />
          </LinkWrapperSC>
        )}
      </TopWrapperSC>
      {/* <BottomWrapperSC> */}
      <ImageSC
        borderRadius={borderRadius}
        widthSize={widthSize}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        bottom={bottom}
        left={left}
        src={img}
      />
      {/* </BottomWrapperSC> */}
    </SlideWrapperSC>
  );
};
// heightImg={ImgHeight} urlImage={img}
const {
  TagsSC,
  ImageSC,
  LinkTextSC,
  LinkIconSC,
  SpanTextSC,
  SlideTextSC,
  TopWrapperSC,
  SlideTitleSC,
  LogoSliderSC,
  TagsWrapperSC,
  LinkWrapperSC,
  SlideWrapperSC,
  SlideTitleWrapperSC,
} = SlideOneStyle();

export default Slide;
