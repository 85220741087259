import { useResize } from "../../lib/hooks/useResize";

import DesktopProject from "./DesktopProjectBlock/DesktopProject";
import MobileProjectBlock from "./MobileProjectBlock/MobileProjectBlock";
import { ProjectBlockStyle } from "./style";

const ProjectBlock = ({mobileRef}) => {
  const { isScreenSm } = useResize();
  return (
    <CustomContainerSC>
      <SectionSC id="project">
      <CustomHTwoTitleSC>ПРОЕКТЫ</CustomHTwoTitleSC>
        {!isScreenSm && <MobileProjectBlock mobileRef={mobileRef} />}
        {isScreenSm && <DesktopProject />}
      </SectionSC>
    </CustomContainerSC>
  );
};

const { SectionSC,
  CustomContainerSC,
  CustomHTwoTitleSC } = ProjectBlockStyle();

export default ProjectBlock;
