import Slide from "./Slide/Slide";
import { Pagination } from "swiper";

import Logo from "./assets/Logo.svg";
import LogoTwo from "./assets/LogoTwo.svg";
import SlideThree from "./SlideThree/SlideThree";
import LogoFour from "./assets/LogoFour.svg";
import { mobileProjectBlock } from "./style";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
const MobileProjectBlock = ({ mobileRef }) => {
  const [swiper, setSwiper] = useState(null);

  const slideTimeout = useCallback(
    (index) => {
      setTimeout(() => {
        swiper.slideTo(index);
      }, 500);
    },
    [swiper]
  );

  const slideChange = useCallback(() => {
    if (swiper) {
      const hash = window.location.hash;
      switch (hash) {
        case "#Machine_learning":
          slideTimeout(0);
          break;
        case "#Blockchain":
          slideTimeout(1);
          break;
        case "#Health":
          slideTimeout(2);
          break;
        case "#Security":
          slideTimeout(3);
          break;
        default:
          break;
      }
    }
  }, [swiper, slideTimeout]);

  useEffect(() => {
    window.addEventListener("hashchange", slideChange);
    return () => {
      window.removeEventListener("hashchange", slideChange);
    };
  }, [swiper, slideChange]);
  return (
    <SwiperSC
      onSwiper={setSwiper}
      modules={[Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      pagination={{ clickable: true }}
      onSlideChange={() => console.log("slide change")}
      hashNavigation={false}
      ref={mobileRef}
    >
      <SwiperSlideSC>
        <Slide
          bottom={-70}
          left={15}
          linkHref={"https://sheetsgpt.ru/"}
          ImgHeight={180}
          linkText={"Ознакомиться подробнее"}
          // textMaxWidth={300}
          Title={"SheetsGPT"}
          TagOne={"Машинное обучение"}
          TagTwo={"Базы данных"}
          backgroundColor={"#2A2735"}
          tagsBorderColor={"#56A2FD"}
          logoSrc={Logo}
          spanColor={"#56A2FD"}
          img={"/ProjectSlideOne.webp"}
          textColor={"#FFFFFF"}
          isLink={true}
          text={`Нейросеть для анализа баз данных и таблиц. Позволяет делать отчеты, строить графики, анализировать данные${" "}`}
          span={`с помощью текстовых запросов.`}
          imgHeight={256}
          imgWidth={489}
          borderRadius={10}
        />
      </SwiperSlideSC>
      <SwiperSlideSC>
        <Slide
          bottom={"-39"}
          left={15}
          ImgHeight={175}
          textMaxWidth={270}
          textColor={"#1C1C1C"}
          Title={"Blockchain archive"}
          TagOne={"Электронный документооборот"}
          TagTwo={"Блокчейн"}
          backgroundColor={"#FFE9E3"}
          tagsBorderColor={"#DE491B"}
          logoSrc={LogoTwo}
          spanColor={"#DE491B"}
          img={"/ProjectSlideTwo.webp"}
          isLink={false}
          text={`Blockchain Archive - надежная распределенная сеть для электронного документооборота предприятия,${" "} `}
          span={`исключающая возможность подделки и упрощающая процесс подписи документов.`}
          imgHeight={218}
          imgWidth={390}
        />
      </SwiperSlideSC>
      <SwiperSlideSC>
        <SlideThree />
      </SwiperSlideSC>
      <SwiperSlideSC>
        <Slide
          linkHref={`https://www.researchgate.net/publication/367043115_MATHEMATICAL_MODEL_FOR_ASSESSING_THREATS_OF_PHYSICAL_INTRUSION_OF_AN_INTRUDER_INTO_A_PROTECTED_OBJECT            `}
          ImgHeight={167}
          linkText={"Ссылка на статью Research Gate"}
          textColor={"#F6F6F6"}
          Title={"Защита помещений от проникновения"}
          TagOne={"Системы безопасности"}
          TagTwo={"Недвижимость"}
          backgroundColor={"#1C399E"}
          tagsBorderColor={"#5C7DF0"}
          logoSrc={LogoFour}
          spanColor={"#FFC700"}
          img={"/ProjectSlideFour.webp"}
          isLink={true}
          text={`Система распределения систем физической защиты на основе плана здания, основанная на${" "} `}
          span={`собственной математической модели.`}
          left={17}
          // imgWidth={90}
          imgHeight={241}
          imgWidth={310}
          widthSize={90}
        />
      </SwiperSlideSC>
    </SwiperSC>
  );
};

const { SwiperSC, SwiperSlideSC } = mobileProjectBlock();

export default MobileProjectBlock;
