import { ServiceBlockStyle } from "./style";
import Container from "../Container/Container";

import { useState } from "react";
import { useResize } from "../../lib/hooks/useResize";
import { HTwoTitle } from "../../ui/Titles/style";

const ServiceBlock = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { isScreenSm } = useResize();
  const tabsValue = [
    {
      tabOne: "Управление вашей командой для создания продукта",
      tabTwo: "Грамотное построение процессов разработки",
      tabThree: "MVP по запросу в кратчайшие сроки",
      image: '/ProjectManagement.webp',
    },
    {
      tabOne: "Инновационные продукты для ваших потребностей",
      tabTwo: "Команда профессионалов под ваш проект",
      tabThree: "Разработка от идеи до создания продукта",
      image: '/Scheme-2.webp',
    },
    {
      tabOne: "Улучшение процессов на всех этапах производства",
      tabTwo: "Снижение затрат без ущерба для качества",
      tabThree: "Долгосрочная стратегия оптимизации",
      image:'/Scheme-3.webp',
    },
  ];
  const DateTitle = <DateTitleSC>Сроки:</DateTitleSC>;
  const handleTabSelect = (tabIndex) => {
    return () => {
      setTabIndex(tabIndex);
    };
  };

  return (
    <ServiceWrapperSC>
      <Container>
        <HTwoTitle>ВИДЫ УСЛУГ</HTwoTitle>
        <TabsWrapperSC>
          <TabSC onClick={handleTabSelect(0)} isActive={tabIndex === 0}>
            Управление проектами
          </TabSC>
          <TabSC onClick={handleTabSelect(1)} isActive={tabIndex === 1}>
            Продуктовая разработка
          </TabSC>
          <TabSC onClick={handleTabSelect(2)} isActive={tabIndex === 2}>
            Оптимизация процессов
          </TabSC>
        </TabsWrapperSC>
        <ListServiceSC>
          <ListItemSC>{tabsValue[tabIndex].tabOne}</ListItemSC>
          <ListItemSC>{tabsValue[tabIndex].tabTwo}</ListItemSC>
          <ListItemSC>{tabsValue[tabIndex].tabThree}</ListItemSC>
        </ListServiceSC>
      </Container>
      <Container>{isScreenSm && DateTitle}</Container>
      <DateServiceWrapperSC>
        <DateServiceImgSC src={tabsValue[tabIndex].image} />
      </DateServiceWrapperSC>
    </ServiceWrapperSC>
  );
};

const {
  TabSC,
  ListItemSC,
  DateTitleSC,
  TabsWrapperSC,
  ListServiceSC,
  DateServiceImgSC,
  ServiceWrapperSC,
  DateServiceWrapperSC,
} = ServiceBlockStyle();

export default ServiceBlock;
