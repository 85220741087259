import styled from "styled-components";
import { mediaQuery } from "../../lib/service/service";
import { SCREEN_TABLE, } from "../../lib/const/breakpoint";

const ServiceWrapperSC = styled("div")`
  margin-bottom: 80px;


  ${mediaQuery(SCREEN_TABLE)} {
    margin-bottom: 30px;
  }

  ${mediaQuery(600)} {
    padding-top: 31px;
    margin-bottom: 20px;
  }
`;

// const TitleSC = styled("h2")`
//   font-weight: 700;
//   font-size: 18px;
// line-height: 22px;
//   letter-spacing: 0.15em;
//   color: #ffffff;
//   margin-bottom: 40px;

//   ${mediaQuery(SCREEN_XL)} {
//     margin-bottom: 40px;
//   }

//   ${mediaQuery(600)} {
//     font-size: 14px;
//     line-height: 17px;
//     margin-bottom: 20px;
//   }
// `;

const TabsWrapperSC = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
  ${mediaQuery(600)} {
    margin-bottom: 20px;
  }
`;

const TabSC = styled("div")`
  box-sizing: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  height: 76px;
  text-align: center;
  color: ${({ isActive }) => (isActive ? "#ffffff" : "#D3D3D3")};
  ${({ isActive }) => (isActive ? "border-bottom: 1px solid #E63434;" : "")}
  font-size: 22px;
line-height: 27px;
  line-height: 39px;
  ${mediaQuery(SCREEN_TABLE)} {
    font-size: 25px;
    line-height: 32px;
  }

  ${mediaQuery(680)} {
    font-size: 20px;
    line-height: 32px;
  }

  ${mediaQuery(600)} {
    height: 50px;
    font-size: 12px;
    line-height: 15px;
    padding: 0 10px;
  }
`;

const ListServiceSC = styled("ul")`
  margin-bottom: 80px;

  ${mediaQuery(600)} {
    margin-bottom: 19px;
  }
`;

const ListItemSC = styled("li")`
  font-weight: 500;
  font-size: 16px;
line-height: 140%;
  color: #ffffff;
  margin-bottom: 12px;
  ${mediaQuery(600)} {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 10px;
  }
`;

const DateTitleSC = styled("h3")`
  max-width: 1080px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  padding: 0 40px;
  margin: 0 auto;
  margin-bottom: 24px;
  letter-spacing: 0.02em;
`;

const DateServiceWrapperSC = styled("div")`
  max-width: 1080px;
  height: 223px;
  width: 100%;
  overflow: hidden;
  overflow-y: hidden;
  margin-left: 98px;
  padding: 0 40px;
  margin: 0 auto;
  scrollbar-width: 0px;

  &::-webkit-scrollbar {
    width: 0px;
  }
  ${mediaQuery(SCREEN_TABLE)} {
    width: auto;
    overflow-x: scroll;
  }

  ${mediaQuery(601)} {
    margin: 0;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 10px;
    height: 170px;
  }
`;

const DateServiceImgSC = styled("img")`
  max-width: 1000px;
  width: 100%;

  ${mediaQuery(SCREEN_TABLE)} {
    width: auto;
    overflow-x: scroll;
  }

  ${mediaQuery(600)} {
    width: auto;
    height: 170px;
    padding: 0 10px 0 0;
  }
`;

export const ServiceBlockStyle = () => ({
  TabSC,
  ListItemSC,
  DateTitleSC,
  TabsWrapperSC,
  ListServiceSC,
  DateServiceImgSC,
  ServiceWrapperSC,
  DateServiceWrapperSC,
});
