import { useState } from "react";
import { FormFeedbackStyle } from "./style";
import emailjs from "@emailjs/browser";
import { HThreeTitle } from "../../../ui/Titles/style";

const FormFeedback = () => {
  const [name, setName] = useState("");

  const [phone, setPhone] = useState("");

  const [process, setProcess] = useState("expectation");

  const [nameError, setNameError] = useState("");

  const [phoneError, setPhoneError] = useState("");

  const onChange = (e, set, setError, type = "text") => {
    if (type === "number") {
      if (!isNaN(+e.target.value)) {
        set(e.target.value);
        return;
      }
      set((prev) => prev);
      return;
    }
    set(e.target.value);
    setError("");
  };

  const sendEmail = (data) => {
    setProcess("pending");
    emailjs
      .sendForm(
        "service_57d146m",
        "template_txi4egp",
        data,
        "owDOPGx-9QHWt5TCJ"
      )
      .then(
        (result) => {
          setProcess("success");
        },
        (error) => {
          setProcess("error");
        }
      );
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!name.length) {
      setNameError("Введите имя");
    } else if (!phone.length) {
      setPhoneError("Введите телефон");
    } else {
      sendEmail(e.target);
    }
  };
  const isDisabledButton = !name.length || !phone.length;

  const isSuccess = process === "success";


  const Form = <FormSC>
  <InputSC
    value={name}
    name="name"
    isError={nameError.length}
    isEmpty={!name.length}
    onChange={(e) => onChange(e, setName, setNameError)}
    placeholder="Имя"
  ></InputSC>
  {nameError && <InputErrorSC>{nameError}</InputErrorSC>}
  <InputSC
    value={phone}
    name="phone"
    type="tel"
    isError={phoneError}
    isEmpty={!phone.length}
    onChange={(e) => onChange(e, setPhone, setPhoneError, "number")}
    placeholder="Телефон"
  ></InputSC>
  {phoneError && <InputErrorSC>{phoneError}</InputErrorSC>}
  <ButtonSubmitSC
    disabled={isDisabledButton}
    isDisabled={isDisabledButton}
    isSuccess={isSuccess}
    type="submit"
  >
    {isSuccess ? "Готово!" : process === 'pending'?'Отправка...': "СВЯЗАТЬСЯ"}
  </ButtonSubmitSC>
</FormSC>

  return (
    <FormSectionSC id="form" onSubmit={(e) => onSubmit(e)}>
      <HThreeTitle isUppercase={true}>Закажите обратный звонок</HThreeTitle>
      {!isSuccess && Form}
      <SuccessWrapperSC isSuccess={isSuccess}><SuccessTitleSC>Заявка отправлена!</SuccessTitleSC></SuccessWrapperSC> 
    </FormSectionSC>
  );
};

const {
  FormSC,
  InputSC,
  InputErrorSC,
  FormSectionSC,
  ButtonSubmitSC,
  SuccessTitleSC,
  SuccessWrapperSC,
} = FormFeedbackStyle();

export default FormFeedback;
