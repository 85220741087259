import { useRef } from "react";
import Footer from "./components/Footer/Footer";
import ProjectBlock from "./components/ProjectBlock/ProjectBlock";
import ServiceBlock from "./components/ServiceBlock/ServiceBlock";
import TitleBlock from "./components/TitleBlock/TitleBlock";
import GlobalStyle from "./style/globalStyle";
function App() {

  const projectRef = useRef(null);
  return (
    <>
      <GlobalStyle />
      <TitleBlock projectRef={projectRef} />
      <ServiceBlock />
      <ProjectBlock mobileRef={projectRef} />
      <Footer />
      {/* <ContactBlock />
      <FormFeedback /> */}
    </>
  );
}

export default App;
