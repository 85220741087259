import styled from "styled-components";

const SlideWrapperSC = styled("div")`
  position: relative;
  background: #30024f;
  border-radius: 25px;
  overflow: hidden;
  height: 380px;
  width: 95%;
  margin: 0 auto;
`;

const TopWrapperSC = styled("div")`
  padding: 15px 15px 0px 15px;
`;

const TagsWrapperSC = styled("div")`
  display: flex;
  margin-bottom: 10px;
`;

const TagsSC = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #ffffff;
  border: 1px solid #b34afa;
  border-radius: 39px;
  height: 22px;
  padding: 5px 10px;
`;

const SlideTitleWrapperSC = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 30px;
`;

const LogoSliderSC = styled("img")`
  margin-right: 10px;
  width: 30px;
  height: 30px;
`;

const SlideTitleSC = styled("h3")`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #f6f6f6;
`;

const SlideTextSC = styled("p")`
  display: inline;
  max-width: ${({ widthText }) => `${widthText}px`};
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #f6f6f6;
`;

const SpanTextSC = styled("span")`
  display: inline;
  color: #fe8c45;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
`;

const LinkWrapperSC = styled("a")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 10px;
`;

const LinkIconSC = styled("img")``;

const LinkTextSC = styled("p")`
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #f6f6f6;
  margin-right: 10px;
`;

const BottomWrapperSC = styled("div")`
  padding-left: 15px;
`;

const ImageSC = styled("img")`
  bottom: -10px;
  left: 15px;
  min-width: 394px;
  width: 100%;
  height: 193px;
  position: absolute;
  border-radius: 14px 0px 25px 6px;
`;

const TextWrapperSC = styled("div")`
  margin-bottom: 15px;
`;

export const SlideThreeStyle = () => ({
  TagsSC,
  ImageSC,
  LinkTextSC,
  LinkIconSC,
  SpanTextSC,
  SlideTextSC,
  TopWrapperSC,
  SlideTitleSC,
  LogoSliderSC,
  TextWrapperSC,
  TagsWrapperSC,
  LinkWrapperSC,
  SlideWrapperSC,
  BottomWrapperSC,
  SlideTitleWrapperSC,
});
