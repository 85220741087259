import { ProjectBlockStyle } from "./style";

import linkIcon from "./assets/linkIcon.svg";

const ProjectBlock = ({
  id,
  tagOne,
  tagTwo,
  tagsColor,
  tagsColorText,
  logo,
  title,
  text,
  textColor,
  imgHeight,
  textMarginBottom,
  imgSrc,
  isLink,
  spanColor,
  titleColor,
  textLink,
  imgSize,
  linkHref,
  imgWidth,
  borderRadius,
  bottomAbsolute,
  backgroundColor,
  borderWrapperColor,
}) => {
  const Link = (
    <LinkWrapperSC target="_blank" href={linkHref}>
      <TextLinkSC>{textLink}</TextLinkSC>
      <LinkIconSC src={linkIcon} />
    </LinkWrapperSC>
  );
  return (
    <BlockWrapperSC
      borderWrapperColor={borderWrapperColor}
      backgroundColor={backgroundColor}
      id={id}
    >
      <TagsWrapperSC>
        <TagSC tagsColorText={tagsColorText} tagsColor={tagsColor}>
          {tagOne}
        </TagSC>
        <TagSC tagsColorText={tagsColorText} tagsColor={tagsColor}>
          {tagTwo}
        </TagSC>
      </TagsWrapperSC>
      <TitleWrapperSC>
        <LogoSC src={logo} />
        <TitleSC titleColor={titleColor}>{title}</TitleSC>
      </TitleWrapperSC>
      <DescrSC
        textColor={textColor}
        spanColor={spanColor}
        textMarginBottom={textMarginBottom}
        dangerouslySetInnerHTML={{ __html: text }}
      ></DescrSC>
      {isLink && Link}
      <ImgBlockSC
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        bottomAbsolute={bottomAbsolute}
        src={imgSrc}
        borderRadius={borderRadius}
        imgSize={imgSize}
      />
    </BlockWrapperSC>
  );
};

const {
  TagSC,
  LogoSC,
  TitleSC,
  DescrSC,
  TextLinkSC,
  LinkIconSC,
  ImgBlockSC,
  LinkWrapperSC,
  TagsWrapperSC,
  TitleWrapperSC,
  BlockWrapperSC,
} = ProjectBlockStyle();

export default ProjectBlock;
